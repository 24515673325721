//Datalab tracker

(function (d, s, b, u) {
    if (!b.__SV) {
        window.tracker = b;
        b._c = [];
        b.init = function (c) {
            function f(b, h) {
                b[h] = function () {
                    b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
                };
            }
            c.trackerUrl = u;
            b._c = c;
            var i = 'track extract_metas identify set_config whoami track_metas track_action loaded getWk'.split(' ');
            for (g = 0; g < i.length; g++)
                f(b, i[g]);
        };
        b.__SV = 1.0;
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s);
        j.async = !0;
        j.src = u + 'javascripts/tracker-2-latest.js';
        f
            .parentNode
            .insertBefore(j, f);
    }
})(document, 'script', window.tracker || [], 'https://t.regionsjob.com/');
tracker.init({
    /* Debug mode pour logs */
    isDebug: true,
    /* Domaine de tracking */
    trackerDomainId: 2,
    cookieConsent: true
});